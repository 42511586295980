import { useContext, useDebugValue } from 'react'
import { SplitContext } from '@splitsoftware/splitio-react'
import SplitIO from '@splitsoftware/splitio/types/splitio'
import useTenant from '@/hooks/useTenant'

export enum SPLITS {
  USE_GOOGLE = 'GoogleGeocoding',
  USE_NEW_SAVED_ADDRESSED = 'NewGmSavedAddresses',
  REMOVE_ITEM_NOTES = 'removeItemNotes',
  USE_NEW_HEADCOUNT_FILTER = 'GMHeadcountFilter',
  BUDGET_CODE_MANDATORY = 'GmMandatoryBudgetCode',
  EAT_FIRST_BRANDING = 'GmEatFirstBranding',
  AUTOPILOT = 'autopilot',
  FLEXINVOICING = 'FlexInvoicing',
  SAME_DAY_GM_DELIVERY = 'sameDayGmDelivery',
  ORDER_TRACKING_MAP_ENABLED = 'orderTrackingMapEnabled',
  RESELLER_MODEL = 'ResellerModel',
  DYNAMIC_NAVIGATION = 'DynamicNavigation',
  IRELAND_IS_LIVE = 'IrelandLive',
  PRICES_EX_TAX_GM = 'showPricesExcludingTaxGm',
  FEEDR_INVOICING_RESELLER = 'feedrInvoicingReseller',
  FEEDR_INVOICING_RESELLER_V2 = 'reseller_v2',
  PRICES_EX_TAX_MENU_MANAGER = 'menuManagerPricesExTax',
  DEFERRED_PAYMENT = 'deferGmPayments',
  FILTER_UPCOMING_ORDERS = 'vpUpcomingOrdersDateFilter',
  CHECKOUT_INTEGRATED_LOGIN_GM = 'integratedLoginGmCheckout',
  EXPRESS_CHECKOUT_GM = 'gmExpressCheckout',
  COUNTRY_SPECIFIC_FILTERS_GM = 'countrySpecificFiltersGm',
  FIVE_STAR_RATINGS = 'useFiveStarRatings',
  SHOW_HIGHLIGHTS_GM = 'showHighlightsGm',
  SHOW_DIVERSITY_TAGS_GM = 'showDiversityTagsGm',
  MENU_ITEM_MUTATIONS_VP = 'useMenuItemMutationsVendorPortal',
  MENU_ITEM_QUERIES_VP = 'menuManagerUseMenuItemQueriesVendorPortal',
  CATERING_ORDER_DUPLICATION = 'CateringOrderDuplication',
  MIN_ORDER_VALUE_EX_TAX = 'MinOrderValueExTax',
  MENU_ITEM_GROUPING_VP = 'menuItemGroupingVp',
  SHOW_UPDATED_ONBOARDING_LINKS = 'showUpdatedOnboardingLinks',
  CUSTOMER_AUTO_INVOICE = 'customerAutoInvoice',
  GM_MUI_MARKETPLACE = 'GMUiMarketPlace',
  ACCOUNT_REFERENCE_FIELDS = 'AccountReferenceFields',
  USE_SYNCHRONOUS_CC_ORDER_PLACEMENT = 'useSynchronousCanteenOrderPlacementWeb',
  USE_GM_VENDOR_MENU_REDESIGN_AUG_2024 = 'useGmVendorMenuRedesignAugust2024',
  GM_ITEM_CATEGORY_ARRAY = 'GMItemCategoryArray',
  USE_SERVER_SIDE_CANTEEN_MENU_CLOSED_LOGIC = 'useServerSideCanteenMenuClosedLogic',
  CUSTOMER_SPLIT_INVOICE = 'customerSplitInvoice',
  GM_CUTOFF_5PM = 'gmCutOff5pm',
  IA_REFUND_CATERING_ORDER_CARD = 'iaRefundCateringOrderCard',
  CUSTOMER_INVOICE_PER_ORDER_RECIPIENT_TYPE = 'customerInvoicePerOrderRecipientType',
  MUI_GM_ORDER_VIEW_VP = 'useMuiGmOrderViewVP',
  CUSTOM_GM_ITEMS = 'allowCustomGmItems',
  GM_CONFIRM_DETAILS_MUI = 'gmConfirmDetailsMUI',
  CUSTOMER_ADMIN_REPORTING = 'customerAdminReporting',
}

export const useFeatureFlag = (treatmentName: SPLITS, attributes: SplitIO.Attributes = {}) => {
  const { client } = useContext(SplitContext)
  const tenant = useTenant()

  const treatmentValue = client?.getTreatment(treatmentName, { ...attributes, tenantId: tenant.id })
  useDebugValue(`${treatmentName}: ${treatmentValue}`)
  if (!client) return false

  return treatmentValue === 'on'
}
